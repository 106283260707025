<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			slider: false,
			data: false,
			count_archive: 0,
		}
	},
	computed: {
	},
	async mounted() {
        await this.ready();
		this.getSlider()
		this.getListEvent()
	},
	methods: {
		getSlider(){
			$("#FoMain #wrapper > *:not(#header)").css("opacity", 0)
			Gen.apirest("/static-hero", {id:7}, (err,resp)=>{
				this.slider = resp.data
				setTimeout(()=>{
					global.SEMICOLON.initialize.fullScreen()
					Gen.loadScript("/frontend/js/custom.js", false)
					$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
				}, 100)
			})
		},
        getListEvent(){
            this.data = false
            let params = Gen.clone(this.$route.query)
            Gen.apirest("/event-list", params, (err, resp)=>{
                this.data = resp.data
                this.count_archive = resp.count_archive
            })
        },
		changePage(page=1){
			this.$router.push({query:Object.assign(Gen.clone(this.$route.query),{page:page})})
		}
	},
	watch:{
		$route(){
			this.getListEvent()
		}
	}
}
</script>
<template>
	<fragment>
		<section id="slider" v-if="slider" class="slider-element  half-slider force-full-screen full-screen clearfix" data-loop="true"
			data-autoplay="5000">
			<div class="force-full-screen full-screen" :style="'background-image: url(\''+uploader(isMobile?slider.ash_mobile_img:slider.ash_img)+'\');'">
			</div>
		</section>

		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nomargin banner-listing pb-4">
					<div class="container banner-listing">
						<div class="text-center">
							<LoadingSpinnerFe v-if="data===false" light />

							<div v-if="data&&!data.total" class="center py-5 py-lg-0">
								<div class="row justify-content-center">
									<div class="col-md-6 col-lg-4">
										<img :src="assets('fo_images', 'empty.svg')" alt="No Results Found">
									</div>
								</div>
								<h3>{{$route.query.archived?"Belum ada event arsip.":"Belum ada event."}}</h3>
							</div>
						</div>
						<div class="banner event" v-for="(v,k) in data.data" :key="k">
							<div class="banner-text-bg"></div>
							<div class="ban-bg" data-aos="zoom-out"
								:style="'background-image: url('+uploader(v.me_image)+')'"></div>
							<div class="row">
								<div class="col-lg-6">
									<div class="banner-text">
										<!-- <div class="writer-block">
											<span><i class="icon-calendar21"></i> {{v.me_publish_date.dates('format')}}</span>
										</div> -->
										<router-link :to="{name:'DetailEvent',params:{slug:v.me_slug}}"><h2>{{v.me_title}}</h2>
										<p>{{v.me_short_desc.limitWord(30)}}</p></router-link>
										<router-link :to="{name:'DetailEvent',params:{slug:v.me_slug}}" class="box-btn">Read More</router-link>
									</div>
								</div>
								<div class="col-lg-6 d-lg-none" data-aos="fade-up" data-aos-delay="150">
									<img :src="uploader(v.me_image)" :alt="v.me_title">
								</div>
							</div>
						</div>
						<div>
							<div class="row">
								<div class="col-sm-6 text-center text-sm-left">
									<Pagination v-if="data" :limit="2" :data="data" @pagination-change-page="changePage" class="pagination pagination-transparent pagination-circle mb-0 mt-3 mt-md-4 justify-content-center justify-content-sm-start">
										<i slot="prev-nav" class="icon-angle-left"></i>
										<i slot="next-nav" class="icon-angle-right"></i>
									</Pagination>
								</div>
								<div class="col-sm-6 text-center text-sm-right">
									<router-link v-if="!$route.query.archived" :to="{query:{archived:true}}" class="primary-btn mt-3 mt-md-4">See Archived Events</router-link>
									<a href="javascript:;" v-else @click="$router.back()" class="primary-btn mt-3 mt-md-4">See Events</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

		</section>
	</fragment>
</template>